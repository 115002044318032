export class AjaxHelper {
  public static CsrfToken(): string {
    return $('meta[name="csrf-token"]').attr("content");
  }

  // set common action before and after each ajax request
  public static setRequestFilter(): void {
    $.ajaxPrefilter((options, originalOptions, jqXHR) => {
      jqXHR.setRequestHeader("X-CSRF-Token", this.CsrfToken());
    });
  }
}

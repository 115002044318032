import * as Sentry from "@sentry/browser";
import { ServerSetting } from "./server_setting";

export class UserSentry {
  public init(): void {
    const dsn = ServerSetting.sentryDsn();
    if (dsn) {
      Sentry.init({
        dsn,
        ignoreErrors: [
          'Blocked a frame with origin "https://www.kaigonohonne.com" from accessing a cross-origin frame',
          'Blocked a frame with origin "https://www.kaigonohonne.com" from accessing a frame with origin',
          "'[object Object]' is not a valid argument for 'Function.prototype.apply'",
          "Can't find variable: MoatMAK",
          "Unexpected identifier",
          "undefined is not an object (evaluating 'window.mraidbridge.*",
          "ChunkLoadError",
          "ResizeObserver loop limit exceeded",
        ],
        allowUrls: ServerSetting.sentryWhitelistUrls(),
        environment: ServerSetting.environment(),
      });
    }
  }
}

/*

// PCとSPで初期表示サイズが違う場合に対応した汎用的なMOREボタン

<div class="js-more-responsive" data-pc-height="300"" data-sp-height="200">
  <div class="js-more-responsive__target">
    〜
  </div>
  <div class="c-more-responsive js-more-responsive__trigger">
    <div class="c-more-responsive__inner">
      <div class="c-link c-more-responsive__link">
        <span class="c-link__text">続きを読む</span>
        <i class="c-link__icon c-icon--x-small c-icon-arrow-down-blue"></i>
      </div>
    </div>
  </div>
</div>

*/

import { BreakPoint } from "../util/breakpoint";

export class ShowMoreResp {
  private readonly $wrap: JQuery;
  private readonly $trigger: JQuery;
  private readonly $target: JQuery;

  public static init(): void {
    $(".js-more-responsive").each((_index, element) => {
      new ShowMoreResp($(element)).init();
    });
  }

  constructor($wrap: JQuery) {
    this.$wrap = $wrap;
    this.$trigger = $wrap.find(".js-more-responsive__trigger");
    this.$target = $wrap.find(".js-more-responsive__target");
  }

  public init(): void {
    this.$trigger.on("click", (e) => {
      e.preventDefault();
      const $trigger = $(e.currentTarget);
      this.showMore($trigger);
    });

    $(window).on("resize", () => {
      this.initDisplay();
    });

    this.initDisplay();
  }

  private initDisplay(): void {
    this.$target.attr("style", "");

    if (!this.$trigger.is(":visible")) {
      return;
    }

    const targetHeight: number = this.$target.height();
    const height = this.getTargetFoldHeight();
    if (targetHeight > height + 100) {
      this.$trigger.css({ display: "block" });
      this.$target.css({ height: height, overflow: "hidden" });
    } else {
      this.$trigger.css({ display: "none" });
    }
  }

  // targetが折り畳み状態の高さを取得する
  private getTargetFoldHeight(): number {
    if (BreakPoint.isPCSmallMin()) {
      return this.$wrap.data("pc-height");
    }
    return this.$wrap.data("sp-height");
  }

  private showMore($trigger: JQuery): void {
    this.$target.css({ height: "auto", overflow: "" });
    $trigger.hide();
  }
}

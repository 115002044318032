import { ScrollHelper } from "../util/scroll_helper";
import { BreakPoint } from "../util/breakpoint";
import { StringHelper } from "../util/string_helper";

export class FixedSidebar {
  public static sidebar: FixedSidebar;
  private readonly $side: JQuery;
  private readonly $main: JQuery;
  private readonly $sideContent: JQuery;
  private readonly pcMin: number;

  public static getInstance(): void {
    if (!this.sidebar) {
      this.sidebar = new FixedSidebar();
      this.sidebar.init();
    }
  }

  constructor() {
    this.$side = $(".js-jg-content-side");
    this.$sideContent = $(".js-jg-side-content");
    this.$main = $(".js-jg-content-main");
    this.pcMin = BreakPoint.PCMin;
  }

  public init(): void {
    if (this.$main.length == 0) {
      return;
    }

    $(window).on("scroll resize", () => {
      this.updateSidePosition();
    });

    this.updateSidePosition();
  }

  public addStopFlag(): void {
    this.$side.data("move", "1");
  }

  public removeStopFlag(): void {
    this.$side.data("move", null);
  }

  private hasStopFlag(): boolean {
    return StringHelper.isPresent(this.$side.data("move"));
  }

  private updateSidePosition(): void {
    if (this.isSP()) {
      this.clearStyle(); // PCからSPへresize時にPC設定したCSSをリセットする
      return;
    }

    if (this.$sideContent.outerHeight() < window.innerHeight) {
      return;
    }

    if (this.hasStopFlag()) {
      this.removeStopFlag();
      return;
    }

    const scrollBottom = ScrollHelper.currentScrollBottom();
    const contentBottom =
      this.$main.offset().top + this.$sideContent.outerHeight();
    const mainBottom = this.$main.offset().top + this.$main.outerHeight();

    if (scrollBottom <= contentBottom) {
      this.clearStyle();
      this.$sideContent.css("position", "static");
    } else if (scrollBottom > contentBottom && scrollBottom < mainBottom) {
      this.$side.css("position", "fixed");
      this.$side.css("bottom", "0");
      const right = (window.innerWidth - this.$side.parent().width()) / 2;
      this.$side.css("right", `${right}px`);
      this.$sideContent.css("position", "static");
    } else if (scrollBottom >= mainBottom) {
      this.clearStyle();
    }
  }

  private isSP(): boolean {
    return window.innerWidth < this.pcMin;
  }

  private clearStyle(): void {
    this.$side.css("position", "");
    this.$side.css("bottom", "");
    this.$side.css("right", "");
    this.$sideContent.css("position", "");
  }
}

export class SlideImages {
  private readonly $prev: JQuery;
  private readonly $next: JQuery;

  constructor() {
    this.$prev = $(".js-slide-image-prev");
    this.$next = $(".js-slide-image-next");
  }

  public init(): void {
    if (!this.modalContainer) return;

    this.$prev.on("click", () => this.prevImage());
    this.$next.on("click", () => this.nextImage());
    this.updateArrowBtn();
    $(this.modalContainer).on("scroll", () => {
      this.updateArrowBtn();
    });
  }

  private prevImage(): void {
    this.modalContainer.scrollTo({
      left: this.modalContainer.scrollLeft - this.innerWidth,
      behavior: "smooth",
    });
  }

  private nextImage(): void {
    this.modalContainer.scrollTo({
      left: this.modalContainer.scrollLeft + this.innerWidth,
      behavior: "smooth",
    });
  }

  private updateArrowBtn(point = this.modalContainer.scrollLeft): void {
    if (point - this.innerWidth / 2 <= 0) {
      this.$prev.fadeOut();
    } else {
      this.$prev.fadeIn();
    }
    if (point + this.innerWidth * 1.5 > this.modalContainer.scrollWidth) {
      this.$next.fadeOut();
    } else {
      this.$next.fadeIn();
    }
  }

  private get innerWidth(): number {
    return this.modalContainer.clientWidth;
  }

  private get modalContainer(): HTMLElement {
    return document.querySelector(".js-slide-image-container");
  }
}

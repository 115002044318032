export class ServerSetting {
  public static getInstance(): ServerSetting {
    if (!this.instance) {
      this.instance = new ServerSetting();
    }

    return this.instance;
  }

  public static sentryDsn(): string {
    return this.getInstance().sentryDsn;
  }

  public static sentryWhitelistUrls(): string[] {
    return this.getInstance().sentryWhitelistUrls;
  }

  public static awsProductionBucket(): string {
    return this.getInstance().awsProductionBucket;
  }

  public static environment(): string {
    return this.getInstance().environment;
  }

  private static instance: ServerSetting;

  public sentryDsn: string;
  public sentryWhitelistUrls: string[];
  public awsProductionBucket: string;
  public environment: string;

  private constructor() {
    const setting = $(".js-setting").data("setting");
    if (!setting) {
      return;
    }

    this.sentryDsn = setting.sentry_dsn;
    this.sentryWhitelistUrls = setting.sentry_whitelist_urls;
    this.awsProductionBucket = setting.aws_production_bucket;
    this.environment = setting.environment;
  }
}

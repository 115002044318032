export class Notification {
  public static init(): void {
    Notification.reserveFadeOut();
  }

  public static show(message: string): void {
    const $elem = $(".js-notification");
    Notification.resetDecoration();
    $elem.addClass("c-notification--notice");
    $elem.text(message);
    $elem.fadeIn();
    Notification.reserveFadeOut();
  }

  public static alert(message: string): void {
    const $elem = $(".js-notification");
    Notification.resetDecoration();
    $elem.addClass("c-notification--alert");
    $elem.text(message);
    $elem.fadeIn();
    Notification.reserveFadeOut();
  }

  private static resetDecoration(): void {
    const $elem = $(".js-notification");
    $elem.removeClass("c-notification--none");
    $elem.removeClass("c-notification--notice");
    $elem.removeClass("c-notification--alert");
  }

  private static reserveFadeOut(delay = 3000): void {
    const hideNotification = (): void => {
      $(".js-notification").fadeOut(800);
    };
    setTimeout(hideNotification, delay);
  }
}

export class DropdownMenu {
  private $content: JQuery;
  private $menu: JQuery;
  private $currentMenu: JQuery;

  public init(): void {
    this.$menu = $(".js-dropdown-menu");
    this.$menu.on("mouseenter", (e) => {
      this.$currentMenu = $(e.currentTarget);
      this.$content = $(`.${this.$currentMenu.data("content")}`);
      this.$content.stop().fadeIn(200);
      this.showDropdownContent();
    });
  }

  private showDropdownContent() {
    const mouseEnter = (): void => {
      this.$content.stop().fadeIn(200);
    };

    const mouseleave = (): void => {
      this.$content.stop().fadeOut(200);
    };

    this.bindMouseEvent(this.$menu, mouseEnter, mouseleave);
    this.bindMouseEvent(this.$content, mouseEnter, mouseleave);
  }

  private bindMouseEvent(
    $element: JQuery,
    mouseEnter: () => void,
    mouseleave: () => void,
  ): void {
    $element.on("mouseenter", mouseEnter);
    $element.on("mouseleave", mouseleave);
  }
}

export class Conversion {
  private readonly URL = "/api/conversion/";
  private csrf: string;

  public init(): void {
    this.csrf = $('meta[name="csrf-token"]').attr("content");
    $(document).on("click", "a[href^='tel:0']", (event) =>
      this.onTelConversion(event),
    );
  }

  private onTelConversion(event: JQuery.ClickEvent<Document>): void {
    const elem: HTMLElement = event.currentTarget;
    const href = elem.getAttribute("href");
    this.post("tel", {
      screen_url: location.href,
      ref_btn: elem.getAttribute("data-ref"),
      jigyosho_id: elem.getAttribute("data-jigyosho-id"),
      tel: href.replace("tel:", ""),
    });
  }

  private post(action: string, log: IConversionLog): void {
    $.ajax({
      type: "POST",
      url: this.URL + action,
      headers: {
        "X-CSRF-Token": this.csrf,
      },
      data: log,
    });
  }
}

interface IConversionLog {
  screen_url: string; // 画面
  ref_btn?: string; // 発火ボタン
  jigyosho_id?: string;
  tel?: string;
}

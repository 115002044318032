export class SlideNav {
  private readonly $body: JQuery;
  private readonly $slideNav: JQuery;
  private readonly $slideNavBackground: JQuery;
  private readonly $slideNavContainer: JQuery;
  private readonly $slideNavWrapper: JQuery;
  private readonly $slideOpenButton: JQuery;
  private readonly $slideCloseButton: JQuery;
  private readonly duration: number;
  private readonly closeButtonDuration: number;
  private readonly easing: string;
  private closed: boolean;
  private scrollTop: number;

  constructor() {
    this.$body = $("body");
    this.$slideNav = $(".js-slide-nav");
    this.$slideNavBackground = $(".js-slide-nav-background");
    this.$slideNavContainer = $(".js-slide-nav-container");
    this.$slideNavWrapper = $(".js-slide-nav-wrapper");
    this.$slideOpenButton = $(".js-slide-nav-open-button");
    this.$slideCloseButton = $(".js-slide-nav-close-button");
    this.duration = 300;
    this.closeButtonDuration = 400;
    this.easing = "easeOutQuart";
    this.closed = true;
    this.scrollTop = 0;
  }

  public init(): void {
    this.$slideOpenButton.on("click", (e) => {
      e.preventDefault();
      this.slideNavOpen();
    });

    this.$slideNavBackground.on("click", () => {
      this.slideNavClose();
    });

    this.$slideCloseButton.on("click", () => {
      this.slideNavClose();
    });

    // slide開いた状態でresize対応
    $(window).on("resize", () => {
      this.updateSlide();
    });
  }

  private slideNavOpen(): void {
    if (!this.closed) {
      return;
    }

    this.$slideNav.show();
    this.$slideNavBackground.css("opacity", 0.95);
    this.$slideCloseButton.fadeIn(400);
    // bodyスクロール禁止
    this.scrollTop = $(window).scrollTop();
    this.$body.addClass("c-slide-nav-fixed").css({ top: -this.scrollTop });

    this.$slideNavContainer.animate(
      {
        right:
          this.$slideNavContainer.outerWidth() - this.$slideNav.outerWidth(),
      },
      {
        duration: this.duration,
        easing: this.easing,
      },
    );
    this.closed = false;
  }

  private slideNavClose(): void {
    if (this.closed) {
      return;
    }

    this.$slideCloseButton.hide();
    this.$slideNavBackground.css("opacity", 0);
    this.$slideNavContainer.animate(
      { right: "-100%" },
      {
        complete: () => {
          this.$slideNav.hide();
        },
        duration: this.duration,
        easing: this.easing,
      },
    );

    // bodyのスクロール解除
    this.$body.removeClass("c-slide-nav-fixed").css({ top: 0 });
    window.scrollTo(0, this.scrollTop);
    this.closed = true;
  }

  private updateSlide(): void {
    if (this.closed) {
      return;
    }

    this.$slideNavContainer.css(
      "right",
      this.$slideNavContainer.width() - this.$slideNav.width(),
    );
  }
}

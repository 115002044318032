export class Modal {
  private readonly $wrapper: JQuery;

  constructor() {
    this.$wrapper = $("#wrapper");
  }

  public init(): void {
    $(".js-modal-frame").hide();
    // bind show event
    this.$wrapper.on("click", ".js-modal-show", (e) => {
      e.preventDefault();
      const $trigger = $(e.currentTarget);
      const target = $trigger.attr("data-modal-target-id");
      const $targetModal = $(`#${target}`);
      this.showModal($targetModal);
    });
    // bind hide event
    this.$wrapper.on("click", ".js-modal-overlay,.js-modal-hide", (e) => {
      e.preventDefault();
      const $trigger = $(e.currentTarget);
      const $modal = $trigger.closest(".js-modal-frame").find(".js-modal");
      this.hideModal($modal);
    });

    this.$wrapper.on("touchmove", ".js-modal-overlay,.js-modal-footer", (e) =>
      e.preventDefault(),
    );
  }

  public showModal($modal: JQuery): void {
    const $frame = $modal.closest(".js-modal-frame");
    const $overlay = $modal.find(".js-modal-overlay");
    if ($modal.length === 0 || $frame.length === 0) {
      return;
    }

    // スクロールの位置を覚えて、閉じる時に戻す
    $modal.data("scroll-top", $(window).scrollTop());

    // bodyをfixedにする
    $(document.body).addClass("c-modal-open");

    // modalを表示
    $frame.show();
    $modal.fadeIn("500");
    this.updateModalSize($modal);
    $overlay.fadeIn("fast");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async hideModal($modal: JQuery): Promise<any> {
    const $frame = $modal.closest(".js-modal-frame");

    window.scrollTo(0, $modal.data("scroll-top"));
    return Promise.all([
      $modal.fadeOut("fast").promise(),
      $frame.fadeOut("fast").promise(),
    ]);
  }

  // モーダルのサイズを更新
  private updateModalSize($modal: JQuery): void {
    let top = 0;
    const $modalFooter = $modal.find(".js-modal-footer");
    const $modalBody = $modal.find(".js-modal-body");
    const $modalHead = $modal.find(".js-modal-head");

    // modalBodyのheight属性を外す
    $modalBody.css("height", "");

    // TOPからの距離を計算
    const modalHeight = $modal.outerHeight();
    const windowHeight = $(window).height();
    const bottomMin = 16;
    const topMin = 16;
    if (modalHeight + bottomMin + topMin > windowHeight) {
      // windowの高さを超えた場合
      top = topMin;
    } else {
      top = (windowHeight - modalHeight) / 2; // 真ん中にする
    }
    $modal.css({ top: `${top}px` });
    // bodyの高さを計算、ページの高さを超える場合は、スクロールさせる
    let modalFooterHeight = 0;
    if ($modalFooter.length > 0) {
      modalFooterHeight = $modalFooter.outerHeight();
    }
    let modalHeadHeight = 0;
    if ($modalHead.length > 0) {
      modalHeadHeight = $modalHead.outerHeight();
    }

    const maxModalBodyHeight =
      $(window).height() -
      bottomMin -
      modalFooterHeight -
      top -
      modalHeadHeight;
    let bodyHeight = $modalBody.outerHeight();
    if (bodyHeight > maxModalBodyHeight) {
      bodyHeight = maxModalBodyHeight;
      $modalBody.outerHeight(bodyHeight);
    }
  }
}

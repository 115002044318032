export class ElementScroll {
  private readonly $scroll: JQuery;
  private readonly $scrollTarget: JQuery;
  private readonly scrollDistance: number;

  public static init(): void {
    $(".js-element-scroll").each((_index, element) => {
      new ElementScroll(element).init();
    });
  }

  constructor(wrap: HTMLElement) {
    this.$scroll = $(wrap);
    this.$scrollTarget = $(this.$scroll.data("scroll-target-selector"));
    this.scrollDistance = parseInt(this.$scroll.data("scroll-target-distance"));
  }

  public init(): void {
    this.$scroll.on("click", (e) => {
      e.preventDefault();
      const toScrollTop = this.$scrollTarget.offset().top + this.scrollDistance;
      $("html,body").animate(
        {
          scrollTop: toScrollTop,
        },
        500,
        "swing",
      );
    });
  }
}

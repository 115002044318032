export class HideFavoriteModal {
  private readonly $checkbox: JQuery;

  constructor() {
    this.$checkbox = $(".js-hide-favorite-modal-checkbox");
  }

  public init(): void {
    this.$checkbox.on("change", (e) => {
      const isChecked = (e.currentTarget as HTMLInputElement).checked;
      this.hideModal(isChecked);
    });
  }

  private hideModal(isChecked: boolean) {
    $.ajax({
      data: { hidden_favorite_modal: isChecked },
      type: "POST",
      url: "/api/favorites/hide_modal",
    });
  }
}

export class WarnLeavePage {
  public static init(): void {
    if (!this.forceShowWarning) {
      return;
    }

    $(".js-warn-to-leave")
      .find("input,textarea")
      .on("change", () => {
        window.onbeforeunload = (): string =>
          "このページを離れようとしています。";
      });

    $(".js-warn-to-leave-ignore").on("click", () => {
      this.cancel();
    });
  }

  public static cancel(): void {
    this.forceShowWarning = false;
    window.onbeforeunload = null;
  }

  private static forceShowWarning = true;
}

import { BreakPoint } from "../util/breakpoint";

export class DropDown {
  private readonly ICON_CLASS = "c-dropdown-menu__icon--up";
  private readonly $dropDown: JQuery;
  private readonly $dropDownTrigger: JQuery;
  private readonly $dropDownTarget: JQuery;
  private readonly $dropDownIcon: JQuery;

  public static init(): void {
    $(".js-drop-down").each((_index, element) => {
      new DropDown(element).init();
    });
  }

  constructor(element: HTMLElement) {
    this.$dropDown = $(element);
    this.$dropDownTrigger = this.$dropDown
      .find(".js-drop-down-trigger")
      .first();
    this.$dropDownIcon = this.$dropDown.find(".js-drop-down-icon").first();
    if (this.$dropDown.data("drop-down-nest")) {
      this.$dropDownTarget = this.$dropDown
        .find(".js-drop-down-target")
        .first();
    } else {
      this.$dropDownTarget = this.$dropDown.find(".js-drop-down-target");
    }
  }

  public init(): void {
    this.initDisplay();

    this.$dropDownTrigger.on("click", () => {
      this.toggleDropDown();
    });
  }

  // hide target if device is sp
  // show target if device is pc, and has class js-drop-down-pc-open
  private initDisplay(): void {
    if (BreakPoint.isSP()) {
      this.$dropDownTarget.hide();
      return;
    }

    if (this.$dropDownTarget.hasClass("js-drop-down-pc-open")) {
      this.show();
    } else {
      this.hide();
    }
  }

  private toggleDropDown(): void {
    if (this.$dropDownTarget.is(":animated")) {
      return;
    }

    this.$dropDownTarget.slideToggle(150);
    this.$dropDownIcon.toggleClass(this.ICON_CLASS);
  }

  private hide(): void {
    this.$dropDownTarget.hide();
  }

  private show(): void {
    this.$dropDownIcon.addClass(this.ICON_CLASS);
  }
}

export class ElementClickable {
  private readonly $element: JQuery;
  private readonly $target: JQuery;

  public static bind(): void {
    $(".js-element-clickable").each((_index, element) => {
      new ElementClickable(element).init();
    });
  }

  constructor(wrap: HTMLElement) {
    this.$element = $(wrap);
    this.$target = this.$element.find("a.js-element-clickable-target");
  }

  public init(): void {
    this.$element.on("mouseover", () => {
      this.$element.css("cursor", "pointer");
    });
    this.$element.on("click", () => {
      this.$target[0].click();
    });
  }
}

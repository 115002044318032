export class ScrollBar {
  public static init(): void {
    $(window).on("resize", () => {
      this.setScrollbarWidth();
    });
    this.setScrollbarWidth();
  }

  private static setScrollbarWidth(): void {
    const width = this.windowWidth();
    let scrollWidth = 0;
    if (width > document.documentElement.clientWidth) {
      scrollWidth = width - document.documentElement.clientWidth;
    }

    document.documentElement.style.setProperty(
      "--scrollbar-width",
      `${scrollWidth}px`,
    );
  }

  // https://stackoverflow.com/questions/36297612/window-innerwidth-in-chromes-device-mode
  private static windowWidth(): number {
    return Math.min(window.innerWidth, window.screen.width);
  }
}

import { ScrollHelper } from "../util/scroll_helper";

export class BrochureFooter {
  private $footer: JQuery;
  private $showLine: JQuery;
  private $hideLine: JQuery;

  constructor() {
    // 条件選択
    this.$footer = $(".js-brochure-footer");
    this.$showLine = $(".js-brochure-footer-show-line");
    this.$hideLine = $(".js-brochure-footer-hide-line");
  }

  public init(): void {
    $(window).on("scroll resize", () => {
      this.fadeInOrOut();
    });
    this.fadeInOrOut();
  }

  private fadeInOrOut(): void {
    if (this.$hideLine.length == 0) {
      return;
    }

    const scrollBottomPos = ScrollHelper.currentScrollTop();
    const showPosition =
      this.$showLine.length > 0 ? this.$showLine.offset().top : 0;
    const hidePosition = this.$hideLine.offset().top;

    if (scrollBottomPos >= showPosition && scrollBottomPos < hidePosition) {
      this.$footer.fadeIn(300);
    } else {
      this.$footer.fadeOut(300);
    }
  }
}

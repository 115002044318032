export class BreakPoint {
  public static JigyoshoSearchSPMaxWidth = 899;
  public static PCMax = 1080;
  public static PCMin = 900;

  public static jigyoshoSearchIsSP(): boolean {
    return this.windowWidth() <= this.JigyoshoSearchSPMaxWidth;
  }

  public static jigyoshoSearchIsPC(): boolean {
    return !this.jigyoshoSearchIsSP();
  }

  public static isPCSmallMin(): boolean {
    return this.windowWidth() >= this.PCSmallMin;
  }

  public static isSP(): boolean {
    return !this.isPCSmallMin();
  }

  public static isPCMax(): boolean {
    return this.windowWidth() >= this.PCMax;
  }

  public static isPCMin(): boolean {
    return this.windowWidth() >= this.PCMin;
  }

  private static PCSmallMin = 769;

  private static windowWidth(): number {
    return window.innerWidth;
  }
}

import { ParsleyFormLoader } from "./parsley_form_loader";

export interface ICity {
  id: number;
  name: string;
}

export interface IZipcode {
  state_id: number;
  city_id: number;
  street: string;
}

export class Address {
  private $state: JQuery;
  private $city: JQuery;
  private $zipcode: JQuery;
  private $town: JQuery;

  constructor() {
    this.$zipcode = $(".js-zipcode");
    this.$state = $(".js-state");
    this.$city = $(".js-city");
    this.$town = $(".js-town");
  }

  public init(): void {
    this.$zipcode.on("keyup blur", () => {
      const zipcode = this.$zipcode.val().toString();
      if (/^\d{7}|^\d{3}-\d{4}/.test(zipcode) === false) {
        return;
      }
      $.ajax({
        cache: false,
        type: "GET",
        url: `/api/zipcode/${zipcode.replace("-", "")}`,
      }).done((zipcodes: Array<IZipcode>) => {
        const zipcode = zipcodes[0];
        if (zipcode) {
          this.setAddress(zipcode);
        }
      });
    });

    this.$state.on("change", (e) => {
      e.preventDefault();

      const stateId = parseInt(this.$state.val().toString());
      if (stateId) {
        this.setState(stateId);
      } else {
        this.updateCity([]);
      }
    });
  }

  private async setAddress(zipcode: IZipcode): Promise<void> {
    this.$state.val(zipcode.state_id);
    await this.setState(zipcode.state_id);
    this.$city.val(zipcode.city_id);
    this.$town.val(zipcode.street);
    // validate
    if (ParsleyFormLoader.hasParsleyForm()) {
      import(
        /* webpackChunkName: 'user/common/parsely' */ "./parsley_form"
      ).then(({ ParsleyForm }) => {
        ParsleyForm.validateField(this.$state);
        ParsleyForm.validateField(this.$city);
        ParsleyForm.validateField(this.$town);
      });
    }
  }

  private updateCity(cities: Array<ICity>): void {
    this.$city.empty();
    this.$city.append($("<option>", { value: "", text: "市区町村" }));
    cities.forEach((city: ICity) => {
      this.$city.append($("<option>", { value: city.id, text: city.name }));
    });
  }

  private async setState(stateId: number): Promise<void> {
    const cities = await this.getCities(stateId);
    this.updateCity(cities);
  }

  private async getCities(stateId: number): Promise<Array<ICity>> {
    return $.ajax({
      cache: false,
      type: "GET",
      url: `/api/city/${stateId}`,
    });
  }
}

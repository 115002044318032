import { ScrollHelper } from "enterprise/javascripts/util/scroll_helper";

export class ImpressionMeasure {
  public static init(): void {
    $(".js-imp-measure").each((_, card) => {
      new ImpressionMeasure($(card)).init();
    });
  }

  private $block: JQuery;
  private readonly eventCategory: string;
  private readonly eventLabel: string;
  private readonly eventValue: string;
  private sentImpression = false;

  constructor($block: JQuery) {
    this.$block = $block;
    this.eventCategory = $block.data("event-category");
    this.eventLabel = $block.data("event-label");
    this.eventValue = $block.data("event-value");
  }

  public init(): void {
    $(window).on("scroll resize", () => {
      this.sendImpEvent();
    });
    this.sendImpEvent();
  }

  private sendImpEvent(): void {
    if (this.sentImpression) {
      return;
    }
    if (!this.isScrolledIntoView()) {
      return;
    }

    gtag("event", "impression", {
      event_category: this.eventCategory,
      event_label: this.eventLabel,
      value: this.eventValue,
    });
    this.sentImpression = true;
    // console.log(`send impression event ${this.eventCategory} ${this.eventLabel} ${this.eventValue} `);
  }

  // 計測エリアの半分が表示されたらimpression計上される
  private isScrolledIntoView(): boolean {
    if (!this.$block.is(":visible")) {
      return false;
    }

    const scrollBottom = ScrollHelper.currentScrollBottom();
    const top = this.$block.offset().top + this.$block.outerHeight() / 2;
    return scrollBottom >= top;
  }
}

export class BrochureJigyosho {
  private $jigyoshoCheckbox: JQuery;
  private $jigyoshoCount: JQuery;
  private $jigyoshoButton: JQuery;

  constructor() {
    this.$jigyoshoCheckbox = $(".js-brochure-jigyosho-checkbox");
    this.$jigyoshoCount = $(".js-brochure-jigyosho-count");
    this.$jigyoshoButton = $(".js-brochure-jigyosho-button");
  }

  public init(): void {
    this.$jigyoshoCheckbox.on("change", (e) => {
      e.preventDefault();

      const checkbox = $(e.currentTarget);
      const jigyoshoId = parseInt(checkbox.val().toString(), 10);
      if (checkbox.prop("checked")) {
        this.addJigyosho(jigyoshoId);
      } else {
        this.removeJigyosho(jigyoshoId);
      }
    });
  }

  private addJigyosho(jigyoshoId: number): void {
    $.ajax({
      data: {
        jigyosho_ids: [jigyoshoId],
      },
      dataType: "json",
      type: "POST",
      url: "/api/brochure/add_jigyoshos",
    }).then((res) => {
      this.updateHeaderCount(res.count);
    });
  }

  private removeJigyosho(jigyoshoId: number): void {
    $.ajax({
      data: {
        jigyosho_ids: [jigyoshoId],
      },
      dataType: "json",
      type: "POST",
      url: "/api/brochure/remove_jigyoshos",
    }).then((res) => {
      this.updateHeaderCount(res.count);
    });
  }

  private updateHeaderCount(count: number): void {
    this.$jigyoshoCount.text(count);
    if (count > 0) {
      this.$jigyoshoButton.removeClass("c-brochure-button--disable");
    } else {
      this.$jigyoshoButton.addClass("c-brochure-button--disable");
    }
  }
}

export class ParsleyFormLoader {
  public static hasParsleyForm(): boolean {
    return $(".js-parsley-form").length > 0;
  }

  public static load(): void {
    if (this.hasParsleyForm()) {
      import(
        /* webpackChunkName: 'user/common/parsely' */ "./parsley_form"
      ).then(({ ParsleyForm }) => {
        ParsleyForm.init();
      });
    }
  }
}

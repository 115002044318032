export class ScrollHelper {
  public static currentScrollTop(): number {
    const $window = $(window);
    const $body = $("body");

    const windowScrollTop = $window.scrollTop() > 0 ? $window.scrollTop() : 0;
    const bodyScrollTop = $body.scrollTop() > 0 ? $body.scrollTop() : 0;
    return windowScrollTop > bodyScrollTop ? windowScrollTop : bodyScrollTop;
  }

  public static currentScrollBottom(): number {
    return this.currentScrollTop() + window.innerHeight;
  }

  public static scrollToElement($element: JQuery, options = { distance: 0 }): void {
    $("html,body").animate({
      scrollTop: $element.offset().top + options.distance
    }, 500, "swing");
  }
}

import { StringHelper } from "../util/string_helper";
import { ServerSetting } from "./server_setting";

// developmentとsandboxなど画像確認用
export class ImageSandbox {
  public static init(): void {
    // production環境は処理しない
    if (this.notUseProductionImage()) {
      return;
    }

    const imageErrors = window.imageErrors || [];
    window.imageErrors = this;
    imageErrors.forEach((image: HTMLElement) => {
      const $image = $(image);

      if (this.isTarget($image) === false) {
        return;
      }

      this.setProductionImage($image);
    });

    // window.load完了前に表示できない画像はerrorイベント取れないため、ここで処理する
    const $images = $("img");
    $images.each((index, image) => {
      const $image = $(image);
      // reset onerror event
      $image.attr("onerror", null);

      if (this.isTarget($image) === false) {
        return;
      }

      if (this.imageLoadFail($image)) {
        this.setProductionImage($image);
      }
    });

    $images.on("error", (e) => {
      const $image = $(e.currentTarget);
      if (this.isTarget($image) === false) {
        return;
      }

      this.setProductionImage($image);
    });
  }

  private static push(target: HTMLElement): boolean {
    const $image = $(target);
    if (this.isTarget($image) === false) {
      return;
    }
    this.setProductionImage($image);
  }

  private static isTarget($image: JQuery): boolean {
    return !$image.data("productionImage");
  }

  private static setProductionImage($image: JQuery): void {
    this.replaceImageSrc($image);
    $image.siblings("source").each((index, source) => {
      this.replaceSourceSrc($(source));
    });
    $image[0].dataset.productionImage = "fallbacked";
  }

  private static imageLoadFail($image: JQuery): boolean {
    return (
      (!$image.data("productionImage") &&
        !(
          "loading" in HTMLImageElement.prototype &&
          $image.attr("loading") === "lazy"
        ) &&
        $image.prop("naturalWidth") === 0) ||
      $image.data("fallbacked")
    );
  }

  private static notUseProductionImage(): boolean {
    return StringHelper.isBlank(ServerSetting.awsProductionBucket());
  }

  // development、sandbox、qaのURLをproductionへ置き換える
  private static getProductionUrl(url: string): string {
    // replace s3 host
    if (url.match(/starplatinum-.*/)) {
      return url.replace(
        /(https?:\/\/.*?)\/([^ ,]*)/g,
        "https://starplatinum.s3.amazonaws.com/$2",
      );
    }

    if (url.match(/image-news-.*/)) {
      return url.replace(
        /(https?:\/\/.*?)\/([^ ,]*)/g,
        "https://image-news.s3.amazonaws.com/$2",
      );
    }

    // replace server host for resize image url
    if (
      url.match(/localhost|sandbox|qa/) &&
      (url.match(/download/) || url.match(/images\/uploads/))
    ) {
      if (url.match("/cdn-|localhost/")) {
        return url.replace(
          /(https?:\/\/.*?)\/([^ ,]*)/g,
          "https://cdn.kaigonohonne.com/$2",
        );
      } else {
        return url.replace(
          /(https?:\/\/.*?)\/([^ ,]*)/g,
          "https://www.kaigonohonne.com/$2",
        );
      }
    }

    return url;
  }

  private static replaceSourceSrc($source: JQuery): void {
    const originalUrl = $source.attr("srcset");
    const productionUrl = this.getProductionUrl(originalUrl);
    if (originalUrl === productionUrl) {
      return;
    }

    $source.attr("srcset", productionUrl);
  }

  private static replaceImageSrc($image: JQuery): void {
    const originalUrl = $image.attr("src");
    const productionUrl = this.getProductionUrl(originalUrl);
    if (originalUrl !== productionUrl) {
      $image.attr("src", productionUrl);
    }

    const originalSrcsetUrl = $image.attr("srcset");
    if (!originalSrcsetUrl) return;

    const productionSrcsetUrl = this.getProductionUrl(originalSrcsetUrl);
    $image.attr("srcset", productionSrcsetUrl);
  }
}
